import { SquareWarningIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SquareWarningIcon';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { Helmet } from 'react-helmet';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { AppContainer } from '../layout/AppContainer';
import { AppFooter } from '../layout/AppFooter';
import { AppHeader } from '../layout/AppHeader';
import { AppMain } from '../layout/AppMain';
import { SceneIcon } from '../scene/SceneIcon';
import { SceneMain } from '../scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '../scene/Typography';

export type PpErrorPage = {
  AppHeaderSlot?: React.ReactElement | null;
  FooterSlot?: React.ReactElement;
  helpText?: string;
  IconSlot?: React.ReactElement;
  message?: string;
  pageTitle?: string;
};

const DefaultAppHeader = <AppHeader backButton />;
export function ErrorPage({
  AppHeaderSlot = DefaultAppHeader,
  FooterSlot,
  helpText,
  IconSlot,
  message,
  pageTitle = message,
}: PpErrorPage): React.ReactElement {
  const { goBack } = useRouter();

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        {AppHeaderSlot === null ? undefined : AppHeaderSlot}
        <SceneMain dataQa="start-over">
          {IconSlot ?? (
            <SceneIcon>
              <SquareWarningIcon color="error" />
            </SceneIcon>
          )}
          <SceneTitleLarge>{message}</SceneTitleLarge>
          <SceneParagraph>{helpText}</SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter>
        {FooterSlot || <PrimaryButton onClick={(): void => goBack()}>Go back</PrimaryButton>}
      </AppFooter>
    </AppContainer>
  );
}
