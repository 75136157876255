import type { ReactElement } from 'react';
import type { TpPaymentMethod, TpPaymentMethodUI } from '@noah-labs/fe-shared-ui-shared';
import { getPaymentMethodUI } from '@noah-labs/fe-shared-ui-shared';
import { MuiSvgIcon } from '../mui/icons/MuiSvgIcon';
import {
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '../mui/lists/ListItem';

type PpPaymentMethod = {
  disablePadding?: boolean;
  hideAddress?: boolean;
  iconHeight?: number;
  method: TpPaymentMethod | undefined;
  paymentMethod?: TpPaymentMethodUI;
  ToggleSlot?: ReactElement | false;
};

export const paymentMethodIconStyles = {
  color: 'text.primary',
  width: '40px',
};

export function PaymentMethod({
  disablePadding = false,
  hideAddress,
  iconHeight,
  method,
  paymentMethod,
  ToggleSlot,
}: PpPaymentMethod): ReactElement {
  const { address, dataQa, primary, primaryTypographyProps, secondary, svg } = getPaymentMethodUI(
    method,
    paymentMethod,
    hideAddress,
  );

  return (
    <ListItemContent data-qa={dataQa} padding={disablePadding ? 0 : undefined}>
      {svg && (
        <ListItemIcon>
          <MuiSvgIcon svg={svg} sx={{ ...paymentMethodIconStyles, height: iconHeight }} />
        </ListItemIcon>
      )}
      <ListItemStartContent data-qa="payment-method-details-content" overflow="hidden">
        <ListItemPrimaryText
          noWrap
          color={primaryTypographyProps?.color}
          data-qa="payment-method-details-primary"
        >
          {primary}
        </ListItemPrimaryText>
        <ListItemSecondaryText data-qa="payment-method-details-secondary">
          {secondary}
        </ListItemSecondaryText>
        {address && (
          <ListItemSecondaryText noWrap mt={0.25}>
            {address}
          </ListItemSecondaryText>
        )}
      </ListItemStartContent>
      {ToggleSlot && <ListItemEndContent>{ToggleSlot}</ListItemEndContent>}
    </ListItemContent>
  );
}
