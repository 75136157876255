import { useCallback } from 'react';
import { type FieldError, get, useFormContext } from 'react-hook-form';
import type { NumberFormatValues } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import type { PpInputFieldAtom } from './atoms/InputFieldAtom';
import { InputFieldAtom } from './atoms/InputFieldAtom';

type TpInputFieldAtomProps = Omit<PpInputFieldAtom, 'defaultValue' | 'ref' | 'type' | 'value'>;

export type PpFormatInputField = {
  className?: string;
  InputFieldAtomProps: TpInputFieldAtomProps;
};
export function FormatInputField({
  className,
  InputFieldAtomProps,
}: PpFormatInputField): React.ReactElement {
  const { getValues, setValue } = useFormContext();

  const handleValueChange = useCallback(
    (values: NumberFormatValues) => {
      setValue(InputFieldAtomProps.name, values.value, { shouldValidate: true });
    },
    [InputFieldAtomProps.name, setValue],
  );

  const { formState } = useFormContext();

  const fieldError: FieldError | undefined = get(formState.errors, InputFieldAtomProps.name);

  return (
    <NumericFormat
      thousandSeparator
      allowNegative={false}
      className={className}
      customInput={InputFieldAtom}
      errorMessage={fieldError?.message}
      value={getValues(InputFieldAtomProps.name)}
      onValueChange={handleValueChange}
      {...InputFieldAtomProps}
    />
  );
}
