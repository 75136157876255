import type { TpAddress } from '../types';

export function formatAddress(address: TpAddress | null | undefined): string {
  if (!address) {
    return '';
  }

  const { addressLine1, addressLine2, city, state, zip } = address;
  return [addressLine1, addressLine2, state, city, zip].filter(Boolean).join(', ');
}
