/* eslint-disable react/no-multi-comp */
import type { LinkProps, StackProps, TypographyProps } from '@mui/material';
import { Card, CardContent as CardContentComponent, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { styledMixins } from '../theme/styledMixins';

export const CtaCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'backgroundImage' })<{
  backgroundImage: string;
}>(({ backgroundImage, theme }) => ({
  backgroundColor: theme.palette.ctaCards.white.dark,
  borderRadius: theme.borderRadius.md,
  boxShadow: theme.palette.ctaCards.white.dark,
  flexDirection: 'column',
  minHeight: '100px',
  position: 'relative',
  width: '100%',
  '::before': {
    ...styledMixins.bgImageCoverNoRepeat(backgroundImage),
    ...styledMixins.stretchAll,
    content: '""',
    height: '100%',
    position: 'absolute',
  },
  ...styledMixins.colCentered,
}));

export const CtaCardContentOverlay = styled('div')(({ theme }) => ({
  '::before': {
    ...styledMixins.stretchAll,
    backgroundColor: theme.palette.grayscale.black80,
    content: '""',
    height: '100%',
    position: 'absolute',
    zIndex: -1,
  },
}));

export const CtaCardContent = styled(CardContentComponent)({
  flex: 1,
  textAlign: 'left',
  textDecoration: 'none',
  width: '100%',
  zIndex: 1,
  ...styledMixins.rowCentered,
});

export const CtaCardFooter = styled(Stack)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(0, 2, 2),
  width: '100%',
  zIndex: 1,
}));

export function CtaCardTitle({ ...props }: TypographyProps): React.ReactElement {
  return (
    <Typography
      gutterBottom
      color="grayscale.white"
      component="h3"
      variant="paragraphBodyLBold"
      {...props}
    />
  );
}

export function CtaCardStartContent({ ...props }: StackProps): React.ReactElement {
  return <Stack sx={{ flex: 1 }} {...props} />;
}

export function CtaCardEndContent({ ...props }: StackProps): React.ReactElement {
  return <Stack sx={{ textAlign: 'right' }} {...props} />;
}

export function CtaCardSubTitle({ ...props }: TypographyProps): React.ReactElement {
  return (
    <Typography
      sx={{ color: (theme) => theme.palette.ctaCards.white.contrastText, whiteSpace: 'pre-wrap' }}
      variant="paragraphBodyS"
      {...props}
    />
  );
}

export function CtaCardButton({ ...props }: LinkProps): React.ReactElement {
  return <Link sx={{ width: '100%', zIndex: 0 }} {...props} />;
}
