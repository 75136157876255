/// <reference types="@noah-labs/ts-config/@types/images" />

import type { ForwardRefExoticComponent, RefAttributes } from 'react';
import type { TpFeFeatureFlag } from '@noah-labs/fe-shared-data-access-user';
import type {
  TpCryptoCurrency,
  TpCryptoNetwork,
  TpFiatCurrency,
} from '@noah-labs/shared-currencies';
import type { FiatPaymentCardScheme } from '@noah-labs/shared-schema-gql';
import type { Channel } from '@noah-labs/shared-schema-oas';
import type * as CSS from 'csstype';
import type { LucideProps } from 'lucide-react';
import type { StateValue } from 'xstate';
import type { z } from 'zod';

type CSSObject = CSS.Properties;

/**
 * Type Naming:
 *
 * - General Types: prefix with Tp, e.g. TpConfig
 * - Props: prefix with Pp, e.g. PpErrorPage
 * - State: prefix with St e.g. StAlerts
 * - Context: prefix with Cx e.g. CxAlertPush
 * - Router Params: prefix with Rp e.g. RpCurrency
 *
 */

/**
 * Props With Children
 */
export type PpWC = {
  children: React.ReactNode;
};
/**
 * Props With Optional Children
 */
export type PpWOC = {
  children?: React.ReactNode;
};

export type TpLucideSvgIcon = ForwardRefExoticComponent<
  Omit<LucideProps, 'ref'> & RefAttributes<SVGSVGElement>
>;

/**
 * Currency Types
 */
export type TpExtraFiatCurrencyUI = {
  svg: TpReactSvg | null;
};
export type TpExtraCryptoCurrencyUI = {
  styles: {
    avatar: CSSObject;
  };
  svg: TpReactSvg;
};
export type TpExtraCryptoNetworkUI = {
  getPublicTxUrl: (publicId: string | null | undefined) => string | undefined;
  styles: {
    border: CSSObject;
    card: CSSObject;
  };
  svg: TpReactSvg;
};

export type TpCryptoCurrencyUI = TpCryptoCurrency &
  TpExtraCryptoCurrencyUI & {
    availableNetworks: (ff: TpFeFeatureFlag) => TpCryptoNetworkUI[];
  };
export type TpFiatCurrencyUI = TpExtraFiatCurrencyUI & TpFiatCurrency;
export type TpAllCurrenciesUI = TpCryptoCurrencyUI | TpFiatCurrencyUI;
export type TpCryptoNetworkUI = TpCryptoNetwork & TpExtraCryptoNetworkUI;
export type TpChannel = z.infer<typeof Channel>;

export enum TpPaymentMethod {
  ApplePay = 'Apple Pay',
  BankAch = 'ACH bank transfer',
  BankFedwire = 'Wire transfer',
  BankSepa = 'SEPA bank transfer',
  BankSortCode = 'Sort Code bank transfer',
  BankSwift = 'SWIFT bank transfer',
  BankTransfer = 'Bank transfer',
  Card = 'Credit/Debit card',
  GooglePay = 'Google Pay',
  IdentifierMobileMoney = 'Mobile money',
  IdentifierPix = 'Pix',
}

export type TpAddress = {
  addressLine1: string;
  addressLine2: string | undefined;
  city: string;
  country: string;
  state: string;
  zip: string;
};

export type TpPaymentBank = TpPaymentMethodBankUI & {
  estimatedSeconds?: string | null | undefined;
  id: string;
  method: TpPaymentMethod | undefined;
  payoutSupported?: boolean;
};

export type TpPaymentCard = TpPaymentMethodCardUI & {
  estimatedSeconds?: string | null | undefined;
  /** Checkout token if card is new, DynamoID if card is saved */
  id: string;
  payoutSupported?: boolean;
};

export type TpPaymentMethodBankUI = {
  accountCurrency: TpFiatCurrencyUI;
  accountHolderAddress: TpAddress | null | undefined;
  accountNumber: string;
  bankCode: string | null | undefined;
  method: TpPaymentMethod | undefined;
};

export type TpPaymentMethodCardUI = {
  billingAddress: TpAddress | null | undefined;
  issuer: string | null | undefined;
  last4: string;
  method: TpPaymentMethod | undefined;
  payoutSupported?: boolean;
  scheme: FiatPaymentCardScheme | string | undefined;
};

export type TpPaymentMethodIdentifierPixUI = {
  identifier: string;
  method: TpPaymentMethod | undefined;
};

export type TpPaymentMethodUI =
  | TpPaymentMethodBankUI
  | TpPaymentMethodCardUI
  | TpPaymentMethodIdentifierPixUI;

export type TpTransactionType = 'Buy' | 'Deposit' | 'PayIn' | 'PayOut' | 'Sell' | 'Withdraw';

export type TpUseError = {
  ApiErrorScene: React.ReactElement | null;
};

/**
 * Use undefined for a value that is not yet known
 * TextOrSkeleton will render loading state if value is null or undefined
 */
export type TpSkeletonText = number | string | null | undefined;

export type TpGetVariablesFn<TVariables = unknown> = (
  nextToken?: string | null | undefined,
) => TVariables;

export type TpGuardedPath = { isValid: boolean; redirectTo: string };

export type PpShouldChangeTarget = {
  currentPath: string;
  currentTarget: StateValue;
  targetPath: string;
};

export type TpKycApprovalStatusUi = {
  color?: string;
  description: string;
  Icon?: React.ReactElement;
};
