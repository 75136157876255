import applepayLight from '@noah-labs/fe-shared-ui-assets/svg/images/applepay-light.svg';
import googlepayLight from '@noah-labs/fe-shared-ui-assets/svg/images/googlepay-light.svg';
import { Landmark } from 'lucide-react';
import type { TpPaymentMethodUI } from '../types';
import { TpPaymentMethod } from '../types';
import { formatAddress } from './formatAddress';
import { getCardSchemeIcon } from './getCardSchemeIcon';
import { isBankPaymentMethod, isCardPaymentMethod, isIdentifierPixMethod } from './paymentMethods';

type TpPaymentMethodInfo = {
  address?: string;
  dataQa?: string;
  primary: string;
  primaryTypographyProps?: { color?: string };
  secondary?: string;
  svg?: TpReactSvg;
};

export function getPaymentMethodUI(
  method: TpPaymentMethod | undefined,
  paymentMethod: TpPaymentMethodUI | undefined,
  hideAddress: boolean | undefined,
): TpPaymentMethodInfo {
  switch (method) {
    case TpPaymentMethod.ApplePay:
      return { dataQa: 'apple-pay', primary: 'Apple Pay', svg: applepayLight };

    case TpPaymentMethod.BankAch: {
      if (isBankPaymentMethod(paymentMethod)) {
        const bankCode = paymentMethod.bankCode ? `- ${paymentMethod.bankCode}` : '';
        return {
          primary: TpPaymentMethod.BankAch,
          secondary: `${paymentMethod.accountNumber} ${bankCode}`,
          svg: Landmark,
        };
      }
      return {
        primary: TpPaymentMethod.BankAch,
        secondary: 'Send from a bank account',
        svg: Landmark,
      };
    }

    case TpPaymentMethod.BankSepa: {
      if (isBankPaymentMethod(paymentMethod)) {
        const bankCode = paymentMethod.bankCode ? `- ${paymentMethod.bankCode}` : '';
        return {
          primary: TpPaymentMethod.BankSepa,
          secondary: `${paymentMethod.accountNumber} ${bankCode}`,
          svg: Landmark,
        };
      }
      return {
        primary: TpPaymentMethod.BankSepa,
        secondary: 'Send from a bank account',
        svg: Landmark,
      };
    }

    case TpPaymentMethod.BankTransfer: {
      if (isBankPaymentMethod(paymentMethod)) {
        const bankCode = paymentMethod.bankCode ? `- ${paymentMethod.bankCode}` : '';
        const showAddress = paymentMethod.accountHolderAddress && !hideAddress;
        return {
          address: showAddress ? formatAddress(paymentMethod.accountHolderAddress) : undefined,
          dataQa: 'bank-transfer',
          primary: `${paymentMethod.accountCurrency.code} account`,
          secondary: `${paymentMethod.accountNumber} ${bankCode}`,
          svg: Landmark,
        };
      }
      return { primary: 'Bank transfer / Add new account', svg: Landmark };
    }

    case TpPaymentMethod.Card:
      if (isCardPaymentMethod(paymentMethod)) {
        return {
          address: !hideAddress ? formatAddress(paymentMethod.billingAddress) : undefined,
          dataQa: `credit-card-${paymentMethod.last4}`,
          primary: paymentMethod.issuer || TpPaymentMethod.Card,
          secondary: `**** ${paymentMethod.last4}`,
          svg: getCardSchemeIcon(paymentMethod.scheme),
        };
      }
      return {
        primary: 'Add New Credit/Debit Card',
        secondary: 'We accept Visa and Mastercard',
        svg: getCardSchemeIcon(undefined),
      };

    case TpPaymentMethod.GooglePay:
      return { dataQa: 'google-pay', primary: 'Google Pay', svg: googlepayLight };

    case TpPaymentMethod.IdentifierPix:
      if (isIdentifierPixMethod(paymentMethod)) {
        return {
          primary: TpPaymentMethod.IdentifierPix,
          secondary: paymentMethod.identifier,
          svg: Landmark,
        };
      }
      return { primary: TpPaymentMethod.IdentifierPix, svg: Landmark };

    default:
      return {
        dataQa: 'choose-payment-method',
        primary: 'Choose a payment method',
        primaryTypographyProps: { color: 'text.link' },
      };
  }
}
