import type { ReactElement } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { FieldError } from 'react-hook-form';
import { styledMixins } from '../theme/styledMixins';

type PpCheckoutField = {
  EndSlot?: React.ReactNode;
  error?: FieldError;
  FieldSlot: React.ElementType;
  focused: boolean;
  label: string;
  placeholder?: string;
  required?: boolean;
};

const StyledContainer = styled('div')({
  position: 'relative',
});

export function CheckoutField({
  EndSlot,
  error,
  FieldSlot,
  focused,
  label,
  placeholder,
  required = true,
}: PpCheckoutField): ReactElement {
  return (
    <FormControl fullWidth error={Boolean(error)} focused={focused} required={required}>
      <InputLabel variant="outlined">{label}</InputLabel>
      <StyledContainer>
        {/* Use a disabled input here to ensure styling is consistent with no layout shifts on load */}
        <OutlinedInput
          fullWidth
          endAdornment={EndSlot ? <InputAdornment position="end">{EndSlot}</InputAdornment> : null}
          placeholder={placeholder}
          slotProps={{
            input: {
              'aria-hidden': true,
              disabled: true,
            },
          }}
        />
        <FieldSlot
          style={{
            ...styledMixins.stretchAll,
            position: 'absolute',
          }}
        />
      </StyledContainer>
      <FormHelperText>{error?.message ?? ' '}</FormHelperText>
    </FormControl>
  );
}
