import { useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { isBTC } from '@noah-labs/shared-currencies';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { useFormContext } from 'react-hook-form';
import type { NumberFormatValues } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { InputFieldAtom } from './atoms/InputFieldAtom';

const charThreshold = 12;
const minimumFontSizeInRem = 1.5;
const percentageToScaleDown = 0.045;

type PpScaleDownText = {
  currentFontSize: number;
  inputLength: number;
  originalFontSize: number;
};
function scaleDownText({
  currentFontSize,
  inputLength,
  originalFontSize,
}: PpScaleDownText): string {
  // Input is not above character threshold, no need to scale
  if (inputLength <= charThreshold) {
    return `${originalFontSize}rem`;
  }

  // Font size is already the lowest allowable, don't scale more
  if (currentFontSize <= minimumFontSizeInRem) {
    return `${currentFontSize}rem`;
  }

  // Reduce the font size by 4.5% for each charachter over the threshold
  const scaleMultiplier = inputLength - charThreshold;
  const scaleFactor = percentageToScaleDown * scaleMultiplier;
  const newFontSize = originalFontSize * (1 - scaleFactor);
  return `${newFontSize}rem`;
}

export type PpAmountField = {
  AmountFieldSecondary: React.ReactElement;
  cryptoUnit: CurrencyUnit | undefined;
  InputFieldAtomProps?: {
    autoFocus?: boolean;
    dataQa?: string;
    disabled?: boolean;
    errorMessage?: string;
    placeholder?: string;
    required?: boolean;
  };
  onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  primaryAmountFieldName: string;
  primaryCurrency: TpCryptoCurrencyUI | TpFiatCurrency | undefined;
  primaryCurrencyType: CurrencyDisplayType | null | undefined;
  value: string;
};

export function AmountField({
  AmountFieldSecondary,
  cryptoUnit,
  InputFieldAtomProps,
  onBlur,
  primaryAmountFieldName,
  primaryCurrency,
  primaryCurrencyType,
  value,
}: PpAmountField): React.ReactElement {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>();
  const { setValue } = useFormContext();

  const styles = {
    field: css`
      position: relative;
      width: 100%;
      margin: ${theme.spacing(4, 0)};

      .MuiFormHelperText-root {
        margin: ${theme.spacing(1, 0, 0)};
      }

      .MuiFormHelperText-root:not(.Mui-error) {
        color: ${theme.palette.text.secondary};
      }

      .MuiInputBase-input {
        text-align: center;
        padding: 0;
      }
      .MuiOutlinedInput-root {
        background-color: transparent;
      }
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    `,
  };

  const handleValueChange = useCallback(
    (values: NumberFormatValues) => {
      setValue(primaryAmountFieldName, values.value);
      if (!inputRef.current) {
        return;
      }
      inputRef.current.style.fontSize = scaleDownText({
        currentFontSize: parseFloat(inputRef.current.style.fontSize),
        inputLength: inputRef.current.value.length,
        originalFontSize: parseFloat(theme.typography.headingXL?.fontSize as string),
      });
    },
    [primaryAmountFieldName, setValue, theme.typography.headingXL?.fontSize],
  );

  const isPrimaryCrypto = primaryCurrencyType === CurrencyDisplayType.Crypto;

  // Decides whether currency symbol should be a prefix or suffix -
  // We want to show a suffix for USDC/USDT
  const isSymbolPrefix =
    (isPrimaryCrypto &&
      cryptoUnit === CurrencyUnit.Default &&
      isBTC(primaryCurrency?.code as CurrencyCode)) ||
    primaryCurrencyType === CurrencyDisplayType.Fiat;

  // Handles specific case for SATS being plural or not
  const userCryptoUnitDisplay =
    isPrimaryCrypto && cryptoUnit === CurrencyUnit.SATS && value === '1'
      ? cryptoUnit.substring(0, cryptoUnit.length - 1)
      : cryptoUnit;

  const cryptoUnitDisplay = isBTC(primaryCurrency?.code as CurrencyCode)
    ? userCryptoUnitDisplay
    : primaryCurrency?.code;

  const phAmount = InputFieldAtomProps?.placeholder || '0';
  const placeholder = isSymbolPrefix
    ? `${primaryCurrency?.symbol ?? ''}${phAmount}`
    : `${phAmount} ${cryptoUnitDisplay ?? ''}`;

  const labelCcy =
    primaryCurrencyType === CurrencyDisplayType.Fiat
      ? primaryCurrency?.code
      : primaryCurrency?.label;

  return (
    <NumericFormat
      {...InputFieldAtomProps}
      autoFocus
      labelSrOnly
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      css={styles.field}
      customInput={InputFieldAtom}
      helperText={AmountFieldSecondary}
      inputRef={inputRef}
      label={`Enter Amount in ${labelCcy ?? ''}`}
      name="displayField"
      placeholder={placeholder}
      prefix={isSymbolPrefix ? primaryCurrency?.symbol : ''}
      size="primaryAmount"
      suffix={!isSymbolPrefix ? ` ${cryptoUnitDisplay ?? ''}` : ''}
      value={value}
      onBlur={onBlur}
      onValueChange={handleValueChange}
    />
  );
}
