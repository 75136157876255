import type { CSSObject } from '@mui/material';
import { Stack } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { HeaderIconButtonGroup } from '../buttons/HeaderIconButtonGroup';
import { useLayoutBalancer } from './useLayoutBalancer';

type PpBalancedStack = PpWC & {
  disableBalancing?: boolean;
  EndIcons?: React.ReactNode;
  fullWidth?: boolean;
  height?: CSSObject['height'];
  StartIcons?: React.ReactNode;
};
/**
 * BalancedStack allows for a centered component when using, or not using, start and end icon groups
 */
export function BalancedStack({
  children,
  disableBalancing = false,
  EndIcons,
  fullWidth = true,
  height,
  StartIcons,
}: PpBalancedStack): React.ReactElement {
  const { endIconsRef, iconStyles, startIconsRef } = useLayoutBalancer({
    disableBalancing,
  });

  const hasIcons = StartIcons || EndIcons;
  /**
   * if balancing is disabled, then we only render HeaderIconButtonGroups which have child icons
   * if balancing is enabled and stack has either icons, we must render both the start & end HeaderIconButtonGroup
   */
  const displayStart = disableBalancing ? StartIcons : hasIcons;
  const displayEnd = disableBalancing ? EndIcons : hasIcons;

  const alignIcons = !height ? 'baseline' : 'center';

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        height,
        justifyContent: hasIcons ? 'space-between' : 'center',
        width: fullWidth ? '100%' : undefined,
      }}
    >
      {displayStart && (
        <HeaderIconButtonGroup
          ref={startIconsRef}
          sx={{ ...iconStyles.start, alignSelf: alignIcons, mr: 'auto' }}
        >
          {StartIcons}
        </HeaderIconButtonGroup>
      )}
      {children}
      {displayEnd && (
        <HeaderIconButtonGroup
          ref={endIconsRef}
          sx={{ ...iconStyles.end, alignSelf: alignIcons, ml: 'auto' }}
        >
          {EndIcons}
        </HeaderIconButtonGroup>
      )}
    </Stack>
  );
}
