/* eslint-disable @shopify/prefer-early-return, no-console */
import chalk from 'chalk';

export enum TpLogLevelNames {
  debug = 1,
  info, // for info & success
  error, // for error & fatal
  off,
}

type TpLogFn = {
  (...args: unknown[]): void;
};

type TpLogStrFn = {
  (msg: string): void;
};

type TpLogger = {
  always: TpLogFn;
  attempt: TpLogFn;
  debug: TpLogFn;
  empty: (lines: number) => void;
  error: TpLogFn;
  fail: TpLogFn;
  fatal: (msg: string, cause?: unknown, ...args: unknown[]) => never;
  highlight: TpLogStrFn;
  info: TpLogFn;
  skipped: TpLogFn;
  step: TpLogFn;
  stepFail: TpLogFn;
  stepSuccess: TpLogFn;
  success: TpLogFn;
  title: TpLogStrFn;
  wait: TpLogFn;
};

/* eslint-disable perfectionist/sort-objects */
export function loggerBase(envLogLevel: string | undefined): TpLogger {
  let setLogLevel = 2;

  if (envLogLevel && envLogLevel in TpLogLevelNames) {
    setLogLevel = TpLogLevelNames[envLogLevel as keyof typeof TpLogLevelNames];
  }

  function getLoggerEnabled(desired: number): boolean {
    return desired >= setLogLevel;
  }

  const logger: TpLogger = {
    // all levels
    title: (msg) => {
      console.log(chalk.bold('\n************', msg, '************\n'));
    },
    highlight: (msg, ...args) => {
      console.log(chalk.magentaBright.bold(msg), ...args);
    },
    empty: (lines) => {
      for (let index = 0; index < lines; index += 1) {
        console.log('');
      }
    },
    always: (...args) => console.log(...args),
    // level debug
    debug: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.debug)) {
        console.debug(chalk.bold('DEBUG:'), ...args);
      }
    },
    // level info
    step: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.info)) {
        console.log(chalk.cyanBright.bold.inverse(' STEP START '), ...args);
      }
    },
    stepSuccess: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.info)) {
        console.log(chalk.greenBright.bold.inverse(' STEP SUCCESS '), ...args);
      }
    },
    info: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.info)) {
        console.log(chalk.blue.bold('INFO:'), ...args);
      }
    },
    skipped: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.info)) {
        console.log(chalk.yellow.italic.bold('SKIPPED:'), ...args);
      }
    },
    attempt: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.info)) {
        console.log(chalk.cyan.bold('ATTEMPT:'), ...args);
      }
    },
    success: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.info)) {
        console.log(chalk.green.bold('SUCCESS:'), ...args);
      }
    },
    wait: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.info)) {
        console.log(chalk.cyan.bold('WAIT:'), ...args);
      }
    },
    // level error
    error: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.error)) {
        console.log(chalk.redBright.bold('ERROR:'), ...args);
      }
    },
    fail: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.error)) {
        console.log(chalk.redBright.bold('FAIL:'), ...args);
      }
    },
    stepFail: (...args) => {
      if (getLoggerEnabled(TpLogLevelNames.error)) {
        console.log(chalk.redBright.bold.inverse(' STEP FAIL '), ...args);
      }
    },
    fatal: (msg, cause, ...args) => {
      if (getLoggerEnabled(TpLogLevelNames.error)) {
        if (cause) {
          console.log(chalk.redBright.bold('FATAL:'), msg, cause, ...args);
        } else {
          console.log(chalk.redBright.bold('FATAL:'), msg, ...args);
        }
      }
      if (cause instanceof Error) {
        throw cause;
      } else if (typeof cause === 'string') {
        throw new Error(`${msg} - ${cause}`);
      } else {
        throw new Error(msg);
      }
    },
  };
  return logger;
}
