import type {
  TpPaymentMethodBankUI,
  TpPaymentMethodCardUI,
  TpPaymentMethodIdentifierPixUI,
  TpPaymentMethodUI,
} from '../types';
import { TpPaymentMethod } from '../types';

export function isCardPaymentMethod(
  pm: TpPaymentMethodUI | undefined,
): pm is TpPaymentMethodCardUI {
  return pm?.method === TpPaymentMethod.Card;
}

export function isBankPaymentMethod(
  pm: TpPaymentMethodUI | undefined,
): pm is TpPaymentMethodBankUI {
  const bankTypes = [
    TpPaymentMethod.BankTransfer,
    TpPaymentMethod.BankAch,
    TpPaymentMethod.BankSepa,
  ];
  return !!pm?.method && bankTypes.includes(pm.method);
}

export function isIdentifierPixMethod(
  pm: TpPaymentMethodUI | undefined,
): pm is TpPaymentMethodIdentifierPixUI {
  return pm?.method === TpPaymentMethod.IdentifierPix;
}
